!(function (e) {
  "use strict";
  e(document).on("turbo:load", function () {
    e.validator.addMethod(
      "email",
      function (e, a) {
        return (
          this.optional(a) ||
          /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/i.test(
            e
          )
        );
      },
      "Votre email ne semble pas valide, merci de le vérifier"
    ),
      e.validator.addMethod(
        "password",
        function (e, a) {
          return (
            this.optional(a) ||
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&><]{7,}$/i.test(e)
          );
        },
        "Votre mot de passe ne semble pas valide, merci de le vérifier (minimum 7 caractères et au moins un chiffre)"
      ),
      e.validator.addMethod(
        "phone",
        function (e, a) {
          return (
            this.optional(a) ||
            /^(\(?\+?[0-9]*\)?)?[0-9\-. \(\)]{9,20}$/.test(e)
          );
        },
        "Il ne s’agit pas d’un numéro de téléphone valide"
      ),
      e.validator.setDefaults({
        ignore: [],
        errorClass: "is-invalid field-error",
        validClass: "is-valid field-validated",
        errorElement: "em",
        highlight: function (a, r, t) {
          e(a).is("input")
            ? "checkbox" == e(a).attr("type")
              ? e(a).parents(".form-checkboxes").addClass(r).removeClass(t)
              : "radio" == e(a).attr("type")
              ? e(a).parents(".form-radios").addClass(r).removeClass(t)
              : e(a).parent().hasClass("input-group")
              ? e(a).parent(".input-group").addClass(r).removeClass(t)
              : e(a).parent().addClass(r).removeClass(t)
            : e(a).parent().addClass(r).removeClass(t),
            e(a).addClass(r).removeClass(t);
        },
        unhighlight: function (a, r, t) {
          e(a).is("input")
            ? "checkbox" == e(a).attr("type")
              ? e(a).parents(".form-checkboxes").removeClass(r).addClass(t)
              : "radio" == e(a).attr("type")
              ? e(a).parents(".form-radios").removeClass(r).addClass(t)
              : e(a).parent().hasClass("input-group")
              ? e(a).parent(".input-group").removeClass(r).addClass(t)
              : e(a).parent().removeClass(r).addClass(t)
            : e(a).parent().removeClass(r).addClass(t),
            e(a).removeClass(r).addClass(t);
        },
        errorPlacement: function (a, r) {
          "checkbox" != e(r).attr("type") &&
            "radio" != e(r).attr("type") &&
            "file" != e(r).attr("type") &&
            !1 === e(r).parent().hasClass("input-group") &&
            r.parent().append(a),
            "checkbox" == e(r).attr("type") &&
              r.parents(".form-checkboxes").append(a),
            "radio" == e(r).attr("type") && r.parents(".form-radios").append(a),
            "file" == e(r).attr("type") &&
              r.parents(".form-file-wrapper").after(a),
            e(r).parent().hasClass("input-group") &&
              r.parents(".input-group").after(a);
        },
      }),
      e(".validate-form").validate(),
      e("#signin-form").length &&
        e("#signin-form").validate({
          rules: {
            "user[email]": { required: true, email: true },
            "user[password]": { required: true, password: !1 },
          },
        }),
      e("#otp-form").length &&
        e("#otp-form").validate({
          rules: { "user[otp_attempt]": { required: true } },
        }),
      e("#accept-invitation-form").length &&
        e("#accept-invitation-form").validate({
          rules: {
            "user[password]": { required: true, password: !1 },
            "user[password_confirmation]": {
              required: true,
              equalTo: "#password_user_password",
            },
          },
        }),
      e("#password-forgotten-form").length &&
        e("#password-forgotten-form").validate({
          rules: { "user[email]": { required: true, email: true } },
        }),
      e("#change-password-form").length &&
        e("#change-password-form").validate({
          rules: {
            "user[password]": { required: true, password: !1 },
            "user[password_confirmation]": {
              required: true,
              equalTo: "#password_user_password",
            },
          },
          messages: {
            "user[password_confirmation]":
              "Vos mots de passe ne sont pas identiques",
          },
        }),
      e("#edit-account-form").length &&
        e("#edit-account-form").validate({
          rules: {
            "user[last_name]": { required: true, minlength: 2 },
            "user[first_name]": { required: true, minlength: 2 },
            "user[email]": { required: true, email: true },
            "user[password]": { password: true },
            "user[password_confirmation]": {
              equalTo: "#user_password",
              password: !1,
            },
            "user[phone]": { phone: true },
            "user[mobile_phone]": { required: true, phone: true },
            "user[manager_id]": { required: true },
            "user[street]": { required: true },
            "user[city]": { required: true, minlength: 2 },
            "user[zip]": { required: true, minlength: 2, digits: true },
            "user[country_id]": { required: true, digits: true },
          },
        }),
      e("#account-share-form").length &&
        e("#account-share-form").validate({
          rules: {
            "account_share[last_name]": { required: true, minlength: 2 },
            "account_share[first_name]": { required: true, minlength: 2 },
            "account_share[email]": { required: true, email: true },
            "account_share[email_confirmation]": {
              required: true,
              equalTo: "#account_share_email",
              email: true,
            },
            "account_share[phone]": { required: true, phone: true },
            "account_share[phone_confirmation]": {
              required: true,
              pequalTo: "account_share_phone",
              phone: true,
            },
          },
        }),
      e("#edit-partner-form").length &&
        e("#edit-partner-form").validate({
          rules: {
            "user[last_name]": { required: true, minlength: 2 },
            "user[first_name]": { required: true, minlength: 2 },
            "user[email]": { required: true, email: true },
            // "user[password]": { password: true },
            // "user[password_confirmation]": {
            //   equalTo: "#user_password",
            //   password: !1,
            // },
            "user[mobile_phone]": { required: true, phone: true },
            "user[manager_id]": { required: true },
            "user[company]": { required: true },
            "user[street]": { required: true },
            "user[city]": { required: true, minlength: 2 },
            "user[zip]": { required: true, minlength: 2, digits: true },
            "user[country_id]": { required: true, digits: true },
          },
        }),
      e("#user-form").length &&
        e("#user-form").validate({
          rules: {
            "user[last_name]": { required: true, minlength: 2 },
            "user[first_name]": { required: true, minlength: 2 },
            "user[email]": { required: true, email: true },
            // "user[password]": { password: true },
            // "user[password_confirmation]": {
            //   equalTo: "#user_password",
            //   password: !1,
            // },
            "user[mobile_phone]": { required: true, phone: true },
          },
        }),
      e(".custom-file-input").on("change", function () {
        var a = e(this).val().split("\\").pop();
        e(this)
          .siblings(".form-file-upload--display")
          .find(".filename")
          .addClass("selected")
          .html(a)
          e(this).valid();
      }),
      e("#situation-form").length &&
        e("#situation-form").validate({
          rules: {
            "evolution[patrimony_ids][]": { required: true },
            "evolution[description]": { required: true },
            "evolution[files][]": {
              required: (el) => {
                el.files && el.files.length < 0;
              },
            },
            "evolution[patrimony_update]": { required: true },
          },
        });
    e("#page-form").length &&
      e("#page-form").validate({
        rules: {
          "page[title]": { required: true },
          "page[seo_title]": { required: true },
          "page[browser_title]": { required: true },
        },
      });
    e("#contact-us-form").length &&
      e("#contact-us-form").validate({
        rules: {
          "contact[contact_subject]": { required: true },
          "contact[body]": { required: true },
          // "contact[file]": { required: true },
          ck1: { required: true },
        },
      });
    e("#naos-form-step-1").length &&
      e("#naos-form-step-1").validate({
        "naos[title]": { required: true },
        "naos[email]": { required: true, email: true },
        "naos[phone]": { required: true },
        "naos[mission_statement_full_name]": { required: true },
        "naos[mission_statement_civility]": { required: true },
      });
    e("#new_naos_user").length &&
      e("#new_naos_user").validate({
        "naos_user[email]": { required: true, email: true },
        "naos_user[password]": { required: true },
        "naos_user[password_confirmation]": { required: true },
      });
    e(".dynamic-profile-select, .js-select-submit").on(
      "change",
      function (evt) {
        e(this).closest("form").submit();
      }
    );
  });
})(jQuery);
